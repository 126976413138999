<template>
    <div
        class="course__item course__test"
        :class="
          test.is_passed ? 'course__item--complete' : 'course__item--active'
        "
    >
        <div class="course__test-index">тест</div>
        <div class="course__test-title">{{ test.name }}</div>
        <test-submenu
            :moduleId="moduleId"
            :test="test"
            class="course__test-action item-admin-action"
            v-if="isEditorCourse && accessEdit"
        />
        <span
            v-tooltip="{content: placeholders.COURSE_EDIT_FORBIDDEN, offset: 7}"
            v-else-if="isEditorCourse && !accessEdit"
            class="submenu__icon"
        />
        <router-link
            :disabled="!test.access"
            v-else-if="!isEditorCourse && course.started && course.access && !test.is_passed"
            :to="`/courses/${course.id}/test/${test.id}`"
            tag="button"
            class="v-btn"
        >
            Пройти
        </router-link>
        <div v-else-if="!isEditorCourse && test.is_passed" class="course__test-action item-action">
        </div>
    </div>
</template>

<script>
import placeholders from 'assets/js/placeholders'

export default {
    name: 'CourseTest',
    components: {
        TestSubmenu : () => import('./TestSubmenu'),
        Icon : () => import('@/components/icon/Icon'),
    },
    props: ['test', 'isEditorCourse', 'moduleId', 'course', 'accessEdit'],
    data () {
        return {
            showDropdown: false,
            placeholders
        }
    }
}
</script>

<style lang="scss" scoped>
@import "#sass/v-style";

.course__test {
    &-index {
        position: absolute;
        left: 0;
        font-weight: 600;
        text-transform: uppercase;
        color: #38b563;
    }

    &-title {
        margin-left: 50px;
    }

    &-action {
        margin-left: auto;

        button {
            margin-top: 0;
        }
    }
}
</style>
